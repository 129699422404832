<template>
  <!-- TODO: this eslint disable has no explanation! -->
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ $t('list.addUpdateToList')}}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="listAddForm"
            v-model="valid"
            v-if="data"
            valid
          >
            <v-text-field
              v-model="data.title"
              :rules="titleRules"
              :label="$t('generic.title')"
              required
            />

            <v-textarea
              v-model="data.description"
              :label="$t('generic.description')"
              rows="5"
              row-height="24"
            />

            <!-- The 'v-if' waits for the 'listsActive' to be set before showing the list -->
            <v-combobox
              v-if="listsActive"
              :label="$t('timeline.selectCreateList')"
              multiple
              v-model="data.lists"
              :items="listsActive"
              item-text="title"
              item-value="id"
              @change="onChangeListItems"
              deletable-chips
              small-chips
              required
              :rules="listsRules"
              :no-data-text="$t('list.noSavedLists')"
            >
              <v-icon
                slot="append"
              >
                mdi-plus
              </v-icon>
            </v-combobox>

            <div class="text-end mt-8 mr-n4 mb-n3">
              <v-btn
                class="mr-2"
                @click="hideDialog()"
              >
              {{$t('generic.cancel')}}
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="submitForm()"
              >
              {{$t('generic.save')}}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'ListTimelineItemForm',

  props: {
    data: {
      type: Object,
      default() {
        return {
          document: null,
          title: null,
          description: null,
          lists: [],
        };
      },
    },
  },

  data() {
    return {
      valid: false,
      dialog: true,
      titleRules: [
        (v) => !!v || this.$t('generic.titleMandatory'),
      ],
      listsRules: [
        (v) => v.length > 0 || this.$t('list.selectOrCreateList'),
      ],
      busy: false,
      disabled: false,
    };
  },

  computed: {
    ...mapGetters({
      listsActive: 'listsActive',
    }),
  },

  methods: {
    onChangeListItems(entry) {
      entry.forEach((element) => {
        if (typeof element === 'string' && element.trim()) {
          const existinglistItem = this.listsActive.find((item) => item.title === element);
          if (!existinglistItem) {
            this.addList(element);
          }
        }
      });
    },
    async addList(entry) {
      const newItem = {
        title: entry,
        description: null,
      };

      // Because a new item is not an object but a string we want to
      // get rid of it and use the new created object instead.
      const index = this.data.lists.indexOf(entry, 0);
      if (index > -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.lists.splice(index, 1);
      }
      // Here we create the new list object and add it to the selected items
      await this.$store.dispatch('saveList', newItem);
      const newListItem = this.listsActive.find((item) => item.title === entry);
      // eslint-disable-next-line vue/no-mutating-props
      this.data.lists.push(newListItem);
    },

    hideDialog,
    trimText(text) {
      if (text.length > Config.maxListNameLengthBeforeTrim - 1) {
        return `${text.substring(0, Config.maxListNameLengthBeforeTrim)}…`;
      }
      return text;
    },
    async submitForm() {
      if (this.$refs.listAddForm.validate()) {
        this.busy = true;
        this.disabled = true;

        const params = {
          listItem: this.data,
        };

        const success = await this.$store.dispatch('saveListItem', params);
        if (success) {
          this.$store.dispatch('setMessage', {
            message: this.data.lists.length === 1 ? this.$t('list.updateSuccesfullyAddedToList.singular') : this.$t('list.updateSuccesfullyAddedToList.plural'),
            type: 'success',
          });
        }
        this.hideDialog();
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('getActiveLists');
  },
};
</script>
